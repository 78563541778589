<template>
  <div class="_main_wrapper relative flex font-poppins">
    <survey-list class="i-left-panel shadow-on" />
    <div class="i-right-panel">
      <div class="ws-heading-box relative flex flex-col pb-0">
        <workspace-menu />
      </div>

      <div class="response-body">
        <response-header />
        <responses />
      </div>
    </div>
  </div>
</template>

<script>
import SurveyList from "../../../components/survey/response/SurveyList";
import ResponseHeader from "../../../components/survey/response/ResponseHeader";
import Responses from "../../../components/survey/response/Responses";
import {mapGetters} from "vuex";
export default {
  name: "ResponseList",
  components: {Responses, ResponseHeader, SurveyList},
  created() {
    document.title = "Survey";
    this.$Progress.start();
    this.$store.dispatch("survey/getSurveys")
        .then(response => {
          if(this.activeSurvey) {
            this.$store.dispatch("survey/getAllResponses", this.$store.state.survey.activeSurvey);
          }
          this.$Progress.finish();
        });
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      activeSurvey: "survey/activeSurvey",
      responses: "survey/responses"
    })
  },
  methods: {
    gotoSurvey() {
      this.$router.push({ name: 'survey.landing' });
    },
  }
}
</script>

<style scoped>
.response-body {
  margin-left: 40px;
  width: 1455px;
  margin-top: 15px;
}
</style>
