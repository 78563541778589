<template>
  <div class="qa-div fs-14">
    <h2 class="fs-16 text-primary-one fw-600">
      <span>Q{{ serial + 1 }}.</span>
      {{ item.title }}
    </h2>
    <div class="flex">
      <div class="i-ml-35 question-options">
        <h2 class="fs-14 mt-5 text-primary-one" v-for="(option, optIndex) in item.options" :key="optIndex" :class="{'inst-global-text-color': maxIndex === optIndex}">
          Option {{ optIndex + 1 }}: {{ option.title }}
        </h2>
      </div>
      <horizontal-chart v-if="listing === 'horizontal'" :data="item.options" :options-text="optionsText" :maxIndex="maxIndex" />
      <donut-chart v-if="listing === 'pie'" :data="item.options" :options-text="optionsText" :maxIndex="maxIndex" :render="false" />
      <bar-chart v-if="listing === 'bar'" :data="item.options" :options-text="optionsText" :maxIndex="maxIndex"/>
    </div>
  </div>
</template>

<script>
import HorizontalChart from "../chart/HorizontalChart";
import DonutChart from "../chart/DonutChart";
import BarChart from "../chart/BarChart";
export default {
  name: "OrderRanking",
  props: ['serial', 'item', 'listing', 'response', 'count'],
  components: {BarChart, DonutChart, HorizontalChart},
  created() {
    this.getTheRank();
  },
  data() {
    return {
      maxIndex: null,
      optionsText: []
    }
  },
  methods: {
    getTheRank() {
      const self = this;
      let maxValue = 0;
      if(self.item.options) {
        self.item.options.forEach((opt, index) => {
          let tempPos = [];
          self.response.forEach((pos, posIndex) => {
            let pushedIndex = pos.findIndex(el => el === (index + 1));
            tempPos.push(pushedIndex);
          });
          opt.rank = self.maxRepeating(tempPos);
          this.optionsText.push(opt.rank.maxValue);
          opt.percentage = (opt.rank.maxCount / self.count) * 100;
          opt.flat_value = opt.rank.maxCount;

          if(opt.rank.maxValue === 1) {
            maxValue = opt.percentage;
            self.maxIndex = index;
          }
        });
      }
    },
    maxRepeating(arr) {
      let itemsMap = {};
      let maxValue = 0;
      let maxCount = 0;
      for (let item of arr) {
        if (itemsMap[item] == null) {
          itemsMap[item] = 1;
        } else {
          itemsMap[item]++;
        }

        if (itemsMap[item] > maxCount) {
          maxValue = item;
          maxCount = itemsMap[item];
        }
      }
      return {
        maxValue: maxValue + 1,
        maxCount: maxCount
      };
    }
  }
}
</script>

<style scoped>
.question-options {
  width: 785px;
}
</style>
