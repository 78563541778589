<template>
  <div class="">
    <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">

    <div class="survey-list-box">
      <button
          v-for="(survey, index) in getSurveys" :key="index"
          @click="toggleSurvey(survey.id)"
          :class="{'bg-primary-four text-primary-three': parseInt(activeSurvey) === parseInt(survey.id)}"
          class="ws-folder-button focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three border-primary-four i-border-1 shadow-one w-full mt-8 rounded flex items-center justify-center">
        {{ survey.title }}
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SurveyList",
  computed: {
    ...mapGetters({
      surveys: "survey/surveys",
      activeSurvey: "survey/activeSurvey"
    }),
    getSurveys() {
      return this.surveys['own'];
    }
  },
  methods: {
    toggleSurvey(id) {
      this.$store.state.survey.activeSurvey = id;
      this.$store.dispatch("survey/getAllResponses", id);
    }
  }
}
</script>

<style scoped>
.i-insider-logo {
  margin-bottom: 45px;
}
.survey-list-box {
  height: 915px;
}
</style>
