<template>
  <div>
    <div class="mt-5 flex flex-col items-center i-ml-30">
      <div class="flex hor-chart items-center" v-for="(item, index) in data" :key="index" :class="{'hor-chart-py': index > 0}">
        <div class="flex items-center text-right">
          <h2 class="fs-14 text-primary-pne mr-4 uppercase left-hints-text" v-if="optionsText === 'number'">{{ index + 1 }}</h2>
          <h2 class="fs-14 text-primary-pne mr-4 uppercase left-hints-text" v-else>{{ optionsText[index] }}</h2>
        </div>
        <div class="relative flex">
          <div class="rounded bg-primary-three border-secondary-five i-border-1 wrapper-chart">
            <div class="progressbar auto-progress" :style="{'width': item.percentage + '%'}" :class="{'bg-primary-four': index === maxIndex, 'bg-secondary-four': index !== maxIndex}"></div>
          </div>
          <h2 class="fs-14 text-primary-pne ml-4 percent-text">{{ item.percentage > 0 ? Math.round(item.percentage) : 0 }}%</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HorizontalChart",
  props: ['data', 'optionsText', 'maxIndex']
}
</script>

<style scoped>
.hor-chart {
  width: 490px;
}
.progressbar {
  height: 18px;
}
.wrapper-chart {
  width: 420px;
  height: 20px
}
.hor-chart-py {
  padding-top: 20px;
}
.percent-text {
  width: 46px;
}
.left-hints-text {
  width: 47px;
}
</style>
