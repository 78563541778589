<template>
  <div class="qa-div fs-14" v-if="item.options && item.options.length > 0">
    <h2 class="fs-16 fw-600">
      <span class="text-primary-one">Q{{ serial + 1 }}.</span>
      {{ item.title }}
    </h2>
    <div class="flex">
      <div class="i-ml-35 question-options">
        <h2 class="fs-14 mt-5 flex" v-for="(option, optIndex) in optionsText" :key="optIndex" :class="{'maximum': optIndex === maxIndex}">
          <span v-if="optIndex === 0 || optIndex === 4" class="opt-hint">{{ item.options[optIndex === 0 ? optIndex : 1].title }}</span>
          <span class="opt-title-width" :class="{'ml-8': optIndex === 0 || optIndex === 4, 'ml-opt': optIndex !== 0 && optIndex !== 4}">Rating Scale:</span>
          <span class="ml-2">{{ option }}</span>
        </h2>
      </div>
      <horizontal-chart v-if="listing === 'horizontal'" :data="options" :options-text="optionsText" :maxIndex="maxIndex" />
      <donut-chart v-if="listing === 'pie'" :data="options" :options-text="optionsText" :maxIndex="maxIndex" :render="true" />
      <bar-chart v-if="listing === 'bar'" :data="options" :options-text="optionsText" :maxIndex="maxIndex"/>
    </div>
  </div>
</template>

<script>
import HorizontalChart from "../chart/HorizontalChart";
import DonutChart from "../chart/DonutChart";
import BarChart from "../chart/BarChart";
export default {
  name: "RatingScale",
  props: ['serial', 'item', 'listing', 'response', 'count'],
  components: {BarChart, DonutChart, HorizontalChart},
  created() {
    this.getScaleCount();
  },
  data() {
    return {
      maxIndex: null,
      optionsText: ['1-2', '3-4', '5-6', '7-8', '9-10'],
      options: []
    }
  },
  methods: {
    getScaleCount() {
      let maxValue = 0;
      this.optionsText.forEach((el, index) => {
        let option = {}
        let range = el.split('-')
        let data = this.response.filter(el => el >= parseInt(range[0]) && el <= parseInt(range[1]));
        option.percentage = (data.length / this.count) * 100;
        this.options.push(option)

        if(option.percentage > maxValue) {
            maxValue = option.percentage;
            this.maxIndex = index;
          }
      })
    },
  }
}
</script>

<style scoped>
.question-options {
  width: 785px;
}
.opt-title-width {
  width: 90px;
}
.maximum {
  color: #2A93C0;
}
.ml-opt {
  margin-left: 122px;
}
.opt-hint {
  width: 90px;
}
</style>
