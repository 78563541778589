<template>
  <div class="qa-div fs-14">
    <h2 class="fs-16 text-primary-one fw-600">
      <span>Q{{ serial + 1 }}.</span>
      {{ item.title }}
    </h2>
    <div class="flex i-mt-25">
      <div class="i-ml-35 question-options flex flex-col date-time-opt">
        <p class="text-primary-one fs-14 mb-5">{{ text }}</p>
        <div class="flex items-center mr-5">
          <img @click="prevUser()" src="/images/icons/survey/chevron_left.svg" alt="icon" class="pl-2 pr-3 cursor-pointer" style="filter: invert(1)" v-if="dataIndex !== 0">
          <h2 class="fs-14 text-primary-one px-1">{{ participant }}</h2>
          <img @click="nextUser()" style="filter: invert(1)" src="/images/icons/survey/chevron_left.svg" alt="icon" class="rotate-180 pl-3 pr-2 cursor-pointer" v-if="allResponses.length > dataIndex + 1">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TextType",
  props: ['serial', 'item', 'listing', 'response', 'count'],
  created() {
    const self = this;
    self.responses.responses.forEach(item => {
      let existResponse = item.response.find(qResponse => parseInt(qResponse.question_id) === parseInt(self.item.id));
      if(existResponse) {
        self.allResponses.push({
          survey_responder_id: item.survey_responder_id,
          answer: existResponse.answer,
          survey_user: self.responses.responders.find(user => parseInt(user.id) === parseInt(item.survey_responder_id)).name
        });

        if(!self.dataIndex) {
          self.dataIndex = 0;
          self.participant = self.allResponses[0].survey_user;
          self.text = existResponse.answer;
        }
      }
    });
  },
  data() {
    return {
      responseMode: false,
      text: null,
      allResponses: [],
      participant: '',
      dataIndex: null
    }
  },
  computed: {
    ...mapGetters({
      responses: 'survey/responses'
    })
  },
  methods: {
    nextUser() {
      this.dataIndex = this.dataIndex + 1;
      this.participant = this.allResponses[this.dataIndex].survey_user;
      this.text = this.allResponses[this.dataIndex].answer;
    },
    prevUser() {
      this.dataIndex = this.dataIndex - 1;
      this.participant = this.allResponses[this.dataIndex].survey_user;
      this.text = this.allResponses[this.dataIndex].answer;
    }
  }
}
</script>

<style scoped>
.question-options {
  width: 900px;
}
.wtitle {
  width: 110px;
}
</style>
