<template>
  <div class="flex items-center bar-chart-pos">
    <div class="flex items-center">
      <div class="completion-div">
        <div class="flex items-center" v-for="(item, index) in data" :key="index" :class="{'mt-5': index > 0}">
          <div class="square-10" :style="{'background': maxIndex !== index ? colors[index] : ''}" :class="{'bg-primary-three': maxIndex === index}"></div>
          <h2 class="fs-14 text-primary-one ml-2 heading-text" v-if="optionsText === 'number'">{{ index + 1 }}</h2>
          <h2 class="fs-14 text-primary-one ml-2 heading-text" v-else>{{ optionsText[index] }}</h2>
          <h2 class="fs-14 text-primary-one percent-ml percent-text-w">{{ item.percentage > 0 ? Math.round(item.percentage) : 0 }}%</h2>
        </div>
      </div>
    </div>
    <div class="bar-chart-div relative mt-1">
      <div class="absolute line-box">
        <div class="flex flex-col items-center justify-between">
          <div class="line no-bg"></div>
          <div class="line line-mt"></div>
          <div class="line line-mt"></div>
          <div class="line line-mt"></div>
          <div class="line line-mt"></div>
          <div class="line line-mt no-bg"></div>
        </div>
      </div>
      <div class="absolute" style="bottom: 0; left: 0">
        <div class="flex justify-between items-end px-5 chart-height">
          <div class="bar-box" :style="{'height': item.percentage+'%', 'background': maxIndex !== index ? colors[index] : '', 'width': getBarWidth()+'px'}"
            v-for="(item, index) in data" :key="index"
            :class="{'bg-primary-four': maxIndex === index}">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BarChart",
  props: ['data', 'optionsText', 'maxIndex'],
  data() {
    return {
      colors: ["#88CDF1", "#72C0E9", "#8BD0F4", "#4BA8D9", "#4BA8D9"],
    }
  },
  methods: {
    getBarWidth() {
      let count = this.data.length;
      let width = 130/count;
      return width > 30 ? 30 : width;
    }
  }
}
</script>

<style scoped>
.bar-chart-pos {
  margin-left: 29px;
}
.square-10 {
  border-radius: 1px;
  width: 10px;
  height: 10px;
}
.complete {
  background: #2A93C0;
}
.heading-text {
  width: 80px;
}
.bar-chart-div {
  width: 300px;
  height: 152px;
  margin-left: 22px;
}
.chart-height {
  width: 300px;
  height: 150px;
}
.line {
  height: 1px;
  width: 300px;
  background: #C9C9C9;
}
.line-box {
  border: 1px solid #C9C9C9;
  top: 1px;
  height: 152px !important;
}
.line-mt {
  margin-top: 28px;
}
.bar-box {
  /* width: 30px; */
  border-radius: 4px 4px 0 0;
}
.no-bg {
  background: none;
}
.percent-ml {
  margin-left: 64px;
}
.percent-text-w {
  width: 40px;
}
</style>
