<template>
  <div class="qa-div fs-14" v-if="item.options && item.options.length > 0">
    <h2 class="fs-16 text-primary-one fw-600">
      <span>Q{{ serial + 1 }}.</span>
      {{ item.title }}
    </h2>
    <div class="flex">
      <div class="i-ml-35 question-options">
        <h2 class="fs-14 mt-5 text-primary-one flex" v-for="(option, optIndex) in item.options" :key="optIndex" :class="{'inst-global-text-color': optIndex === maxIndex}">
          <span class="opt-title-width">{{ option.title }}</span>
          <span class="ml-2">{{ option.flat_value }}</span>
        </h2>
      </div>
      <horizontal-chart v-if="listing === 'horizontal'" :data="item.options" :options-text="optionsText" :maxIndex="maxIndex" />
      <donut-chart v-if="listing === 'pie'" :data="item.options" :options-text="optionsText" :maxIndex="maxIndex" :render="true" />
      <bar-chart v-if="listing === 'bar'" :data="item.options" :options-text="optionsText" :maxIndex="maxIndex"/>
    </div>
  </div>
</template>

<script>
import HorizontalChart from "../chart/HorizontalChart";
import DonutChart from "../chart/DonutChart";
import BarChart from "../chart/BarChart";
export default {
  name: "Slider",
  props: ['serial', 'item', 'listing', 'response', 'count'],
  components: {BarChart, DonutChart, HorizontalChart},
  created() {
    let rangeGap = 10 / this.item.options.length;
    let minRange = 0;
    let maxRange = rangeGap;
    this.item.options.forEach(item => {
      item.min = minRange;
      item.max = maxRange;

      this.optionsText.push(`${Math.round(minRange)} - ${Math.round(maxRange)}`);

      minRange = maxRange;
      maxRange = maxRange + rangeGap;
    });
    this.getAnswerCount();
  },
  data() {
    return {
      maxIndex: null,
      optionsText: []
    }
  },
  methods: {
    getAnswerCount() {
      const self = this;
      let maxValue = 0;
      self.item.options.forEach((opt, index) => {
        let data = self.response.filter(el => (el === 0 && el >= opt.min && el <= opt.max) || (el > opt.min && el <= opt.max));
        opt.flat_value = data.length;
        opt.percentage = (data.length / self.count) * 100;
        if(opt.percentage > maxValue) {
          maxValue = opt.percentage;
          self.maxIndex = index;
        }
      });
    }
  }
}
</script>

<style scoped>
.question-options {
  width: 785px;
}
.opt-title-width {
  width: 130px;
}
</style>
