<template>
  <div class="qa-div fs-14">
    <h2 class="fs-16 text-primary-one fw-600">
      <span>Q{{ serial + 1 }}.</span>
      {{ item.title }}
    </h2>
    <div class="flex i-mt-25">
      <div class="i-ml-35 question-options flex date-time-opt flex">
        <div class="flex items-center mr-5">
          <img @click="prevUser()" style="filter: invert(1)" src="/images/icons/survey/chevron_left.svg" alt="icon" class="pl-2 pr-3 cursor-pointer" v-if="dataIndex !== 0">
          <h2 class="fs-14 text-primary-one px-1">{{ participant }}</h2>
          <img @click="nextUser()" style="filter: invert(1)" src="/images/icons/survey/chevron_left.svg" alt="icon" class="rotate-180 pl-3 pr-2 cursor-pointer" v-if="allResponses.length > dataIndex + 1">
        </div>
        <div class="flex items-center">
          <h2 class="fs-14 text-primary-one wtitle">Start Date:</h2>
          <VueCtkDateTimePicker v-model="start_date"
                                class="text-primary-one"
                                :noClearButton="true"
                                :dark="true"
                                :color="'#1e1e22'"
                                :button-color="'#333336'"
                                ref="start_date"
                                :no-label="true"
                                :disabled="!responseMode"
                                :onlyDate="true"
                                :format="'MM-DD-YYYY'"
                                :formatted="'ll'"
                                :no-button-now="true" />
        </div>
        <div class="flex i-ml-35 items-center" v-if="item.options[0].visible_end_date">
          <h2 class="fs-14 text-primary-one wtitle">End Date:</h2>
          <VueCtkDateTimePicker v-model="end_date"
                                class="text-primary-one"
                                :noClearButton="true"
                                :dark="true"
                                :color="'#1e1e22'"
                                :button-color="'#333336'"
                                ref="start_date"
                                :no-label="true"
                                :disabled="!responseMode"
                                :onlyDate="true"
                                :format="'MM-DD-YYYY'"
                                :formatted="'ll'"
                                :no-button-now="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DateTime",
  props: ['serial', 'item', 'listing', 'response', 'count'],
  created() {
    const self = this;
    self.responses.responses.forEach(item => {
      let existResponse = item.response.find(qResponse => parseInt(qResponse.question_id) === parseInt(self.item.id));
      if(existResponse) {
        self.allResponses.push({
          survey_responder_id: item.survey_responder_id,
          answer: existResponse.answer,
          survey_user: self.responses.responders.find(user => parseInt(user.id) === parseInt(item.survey_responder_id)).name
        });

        if(!self.dataIndex) {
          self.dataIndex = 0;
          self.participant = self.allResponses[0].survey_user;
          self.start_date = existResponse.answer.start_date;
          self.end_date = existResponse.answer.end_date;
        }
      }
    });
  },
  data() {
    return {
      responseMode: false,
      start_date: null,
      end_date: null,
      allResponses: [],
      participant: '',
      dataIndex: null
    }
  },
  computed: {
    ...mapGetters({
      responses: 'survey/responses'
    })
  },
  methods: {
    nextUser() {
      this.dataIndex = this.dataIndex + 1;
      this.participant = this.allResponses[this.dataIndex].survey_user;
      this.start_date = this.allResponses[this.dataIndex].answer.start_date;
      this.end_date = this.allResponses[this.dataIndex].answer && this.allResponses[this.dataIndex].answer.end_date ? this.allResponses[this.dataIndex].answer.end_date : null;
    },
    prevUser() {
      this.dataIndex = this.dataIndex - 1;
      this.participant = this.allResponses[this.dataIndex].survey_user;
      this.start_date = this.allResponses[this.dataIndex].answer.start_date;
      this.end_date = this.allResponses[this.dataIndex].answer && this.allResponses[this.dataIndex].answer.end_date ? this.allResponses[this.dataIndex].answer.end_date : null;
    }
  }
}
</script>

<style scoped>
.question-options {
  width: 900px;
}
.wtitle {
  width: 110px;
}
</style>
