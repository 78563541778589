<template>
  <div class="flex items-center flex-start donut-chart-pos">
    <div class="flex items-center">
      <div class="completion-div" :class="{'no-chart': !render}">
        <div class="flex mt-5 items-center" v-for="(option, optIndex) in data" :key="optIndex">
          <div class="square-10" :style="{'background': maxIndex !== optIndex ? colors[optIndex] : ''}" :class="{'bg-primary-three': maxIndex === optIndex}"></div>
          <h2 class="fs-14 font-roboto grey-text ml-2 heading-text uppercase item-width" v-if="optionsText === 'number'">{{ optIndex + 1 }}</h2>
          <h2 class="fs-14 font-roboto grey-text ml-2 heading-text uppercase item-width" v-else>{{ optionsText[optIndex] }}</h2>
          <h2 class="fs-14 font-roboto grey-text items-ml">{{ option.percentage > 0 ? Math.round(option.percentage) : 0 }}%</h2>
        </div>
      </div>
    </div>
    <vc-donut class="i-ml-30" v-bind="props" v-if="render" />
  </div>
</template>

<script>
export default {
  name: "DonutChart",
  props: ['data', 'optionsText', 'maxIndex', 'render'],
  created() {
    const self = this;
    if(self.data.length > 0) {
      self.data.forEach((opt, index) => {
        self.props.sections.push({
          value: opt.percentage > 0 ? Math.round(opt.percentage) : 0,
          color: self.maxIndex === index ? "#2A93C0" : self.colors[index],
        });
      });
    }
  },
  data() {
    return {
      colors: ["#88CDF1", "#72C0E9", "#8BD0F4", "#4BA8D9", "#4BA8D9"],
      props: {
        size: 160,
        sections: [],
        thickness: 15,
        hasLegend: false,
        background: '#F9F9F9'
      }
    };
  }
}
</script>

<style scoped>
.donut-chart-pos {
  margin-left: 120px;
}
.completion-div {
  margin-left: 90px;
}
.square-10 {
  border-radius: 1px;
  width: 10px;
  height: 10px;
}
.complete {
  background: #2A93C0;
}
.viewer {
  background: #CCCCCC;
}
.response {
  background: #888888;
}
.dropped {
  background: #515151;
}
.items-ml {
  margin-left: 77px;
}
.item-width {
  width: 40px;
}
.no-chart {
  /*margin-left: 252px;*/
}
</style>
