<template>
  <div class="response-header-box bg-white shadow-three rounded flex items-center">
    <div class="info-box-1" v-if="responses">
      <div class="sur-title-desc">
        <h2 class="fw-700 fs-18 text-primary-one" style="margin-top: -4px">{{ responses ? responses.title : 'N/A' }}</h2>
        <p class="text-secondary-one fs-14 fw-400 mt-3">
          {{ responses.description ? responses.description : 'Description not available' }}
        </p>
      </div>
      <div>
        <h2 class="fs-14 text-primary-one mt-4"><span class="opacity-75">Created</span>: {{ $lodash.format(responses.created_at, 'MMMM dd, yyyy') }}</h2>
        <h2 class="fs-14 text-primary-one mt-4"><span class="opacity-75">Questions</span>: {{ responses.questions ? responses.questions.length : 0 }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ResponseHeader",
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      responses: "survey/responses"
    })
  }
}
</script>

<style scoped>
.response-header-box {
  margin-bottom: 36px;
  padding: 30px;
  height: 240px;
  overflow-y: scroll;
}
.info-box-1 {
  width: 840px;
}
.square-10 {
  border-radius: 1px;
  width: 10px;
  height: 10px;
}
.complete {
  background: #4F7982;
}
.viewer {
  background: #CCCCCC;
}
.response {
  background: #888888;
}
.dropped {
  background: #515151;
}
.heading-text {
  width: 150px;
}


.chart-div {
  width: 300px;
  height: 152px;
}
.chart-height {
  width: 300px;
  height: 150px;
}
.line {
  height: 1px;
  width: 300px;
  background: #414146;
}
.line-box {
  border: 1px solid #414146;
  top: 4px;
}
.line-mt {
  margin-top: 28px;
}
.bar-box {
  width: 30px;
}
.no-bg {
  background: none;
}
.sur-title-desc {
  height: 120px;
}
</style>
