<template>
  <div class="responses-sec bg-white shadow-three rounded">
    <div class="flex justify-between header-pb" :class="{'header-pb-dev': serverMode === 'development'}">
      <h2 class="fs-16 fw-600 text-primary-one">Summary</h2>
      <div class="flex justify-end icons">
        <img src="/images/icons/survey/hor_chart.svg" alt="icon" class="listing-icon opacity-4 cursor-pointer" @click="toggleListing('horizontal')"
             :class="{'opacity-1 filter-1': listing === 'horizontal'}">
        <img src="/images/icons/survey/donut_chart.svg" alt="icon" class="listing-icon opacity-4 cursor-pointer" @click="toggleListing('pie')"
             :class="{'opacity-1 filter-1': listing === 'pie'}">
        <img src="/images/icons/survey/bar_chart.svg" alt="icon" class="listing-icon opacity-4 cursor-pointer" @click="toggleListing('bar')"
             :class="{'opacity-1 filter-1': listing === 'bar'}">
      </div>
    </div>
    <div class="questions-div">
      <div v-for="(question, index) in responses.questions" :key="index">
        <component class="question-item" :is="getComponent(question.type)"
                    :key="index"
                    :serial="index"
                    :item="question"
                    :response="getResponseData(question.id)"
                    :count="responses.responses.length"
                    :listing="listing"
                    :ref="'q_' + question.id"></component>
      </div>
    </div>
  </div>
</template>

<script>
import survey from "../../../common/survey";
import MultipleChoice from "./question/MultipleChoice";
import Selection from "./question/Selection";
import RatingScale from "./question/RatingScale";
import Rating from "./question/Rating";
import TextType from "./question/TextType";
import Dropdown from "./question/Dropdown";
import OrderRanking from "./question/OrderRanking";
import Slider from "./question/Slider";
import DateTime from "./question/DateTime";
import {mapGetters} from "vuex";
export default {
  name: "Responses",
  components: {
    MultipleChoice, Selection, RatingScale, Rating, TextType, DateTime, Slider, OrderRanking, Dropdown
  },
  created() {
    this.surveyCommonData = survey;
  },
  data() {
    return {
      serverMode: process.env.NODE_ENV,
      surveyCommonData: null,
      listing: 'horizontal',
    }
  },
  computed: {
    ...mapGetters({
      responses: 'survey/responses'
    })
  },
  methods: {
    toggleListing(value) {
      this.listing = value;
    },
    getComponent(type) {
      let data = this.surveyCommonData.questionType;
      let item = data.find(item => parseInt(item.serial) === parseInt(type));
      if(item) {
        return item.component;
      }
      return '';
    },
    getResponseData(question_id) {
      let data = []
      this.responses.responses.forEach(item => {
        let qdata = item.response.find(res => res.question_id == question_id)
        if (qdata) {
          data.push(qdata.answer)
        }
      });

      return data
    }
  }
}
</script>

<style scoped>
.responses-sec {
  width: 100%;
  height: 655px;
  overflow-y: auto;
  padding: 22px 30px;
}
.filter-1 {
  filter: invert(1) !important;
}
.header-pb-dev {
  padding-bottom: 20px !important;
  border-bottom: 1px solid #414141;
}
.header-pb {
  padding-bottom: 15px;
  border-bottom: 1px solid #414141;
}
.listing-icon {
  margin-right: 30px;
  filter: invert(0.5);
}
.icons .listing-icon:last-child {
  margin-right: 0;
}
.listing-icon:hover {
  opacity: 1;
  filter: invert(1);
}
.question-item {
  padding-bottom: 30px;
  padding-top: 30px;
  border-bottom: 1px solid #414141;
}
.questions-div {
  height: 570px;
  overflow-y: auto;
}
</style>
