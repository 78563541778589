import { render, staticRenderFns } from "./RatingScale.vue?vue&type=template&id=247d760e&scoped=true&"
import script from "./RatingScale.vue?vue&type=script&lang=js&"
export * from "./RatingScale.vue?vue&type=script&lang=js&"
import style0 from "./RatingScale.vue?vue&type=style&index=0&id=247d760e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247d760e",
  null
  
)

export default component.exports