<template>
  <div class="qa-div fs-14">
    <h2 class="fs-16 text-primary-one fw-600">
      <span class="text-primary-one fw-600">Q{{ serial + 1 }}.</span>
      {{ item.title }}
    </h2>
    <div class="flex">
      <div class="i-ml-35 question-options relative">
        <star-rating v-for="(num, index) in stars" :key="index"
          :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
          :max-rating="num"
          :increment="1"
          :show-rating="false"
          :border-width="1"
          :active-on-click="false"
          border-color="#8D8D8D"
          :inactive-color="maxIndex == index ? '#2B80AD' : '#F9F9F9'"
          :star-size="27"
          :padding="28"
          :active-color="maxIndex == index ? '#2B80AD' : '#F9F9F9'"
          class="fs-14 star-box">
        </star-rating>
      </div>
      <horizontal-chart v-if="listing === 'horizontal'" :data="options" :options-text="'number'" :maxIndex="maxIndex"/>
      <donut-chart v-if="listing === 'pie'" :data="options" :optionsText="'number'" :maxIndex="maxIndex" :render="true"/>
      <bar-chart v-if="listing === 'bar'" :data="options" :options-text="'number'" :maxIndex="maxIndex"/>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import HorizontalChart from "../chart/HorizontalChart";
import DonutChart from "../chart/DonutChart";
import BarChart from "../chart/BarChart";
export default {
  name: "Rating",
  props: ['serial', 'item', 'listing', 'response', 'count'],
  components: {BarChart, DonutChart, HorizontalChart, StarRating},
  data() {
    return {
      maxIndex: null,
      stars: [1, 2, 3, 4, 5],
      options: []
    }
  },
  created() {
    this.getStarCount();
  },
  methods: {
    getStarCount() {
      let maxValue = 0;
      this.stars.forEach((opt, index) => {
        let option = {}
        let data = this.response.filter(el => el == opt);
        option.percentage = (data.length / this.count) * 100;
        this.options.push(option)

        if(option.percentage > maxValue) {
            maxValue = option.percentage;
            this.maxIndex = index;
          }
      })
    }
  }
}
</script>

<style scoped>
.question-options {
  width: 785px;
}
.star-box {
  margin-top: 18px;
}
</style>
