<template>
  <div class="qa-div fs-14">
    <h2 class="fs-16 text-primary-one fw-600">
      <span>Q{{ serial + 1 }}.</span>
      {{ item.title }}
    </h2>
    <div class="flex">
      <div class="i-ml-35 question-options">
        <h2 class="fs-14 text-primary-one mt-5" v-for="(option, index) in item.options" :key="index" :class="{'maximum': index === maxIndex}">
          {{ option.title }}
        </h2>
      </div>
      <horizontal-chart v-if="listing === 'horizontal'" :data="item.options" :optionsText="optionsText" :maxIndex="maxIndex"/>
      <donut-chart v-if="listing === 'pie'" :data="item.options" :optionsText="optionsText" :maxIndex="maxIndex" :render="true" />
      <bar-chart v-if="listing === 'bar'" :data="item.options" :optionsText="optionsText" :maxIndex="maxIndex" />
    </div>
  </div>
</template>

<script>
import HorizontalChart from "../chart/HorizontalChart";
import DonutChart from "../chart/DonutChart";
import BarChart from "../chart/BarChart";
export default {
  name: "Selection",
  props: ['serial', 'item', 'listing', 'response', 'count'],
  components: {BarChart, DonutChart, HorizontalChart},
  created() {
    this.getAnswerCount()
  },
  data() {
    return {
      maxIndex: null,
      optionsText: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
    }
  },
  methods: {
    getAnswerCount() {
      let maxValue = 0;
      if (this.item.options) {
        this.item.options.forEach((opt, index) => {
          let data = this.response.filter(el => el == index)
          opt.percentage = (data.length/this.count) * 100
          if(opt.percentage > maxValue) {
            maxValue = opt.percentage;
            this.maxIndex = index;
          }
        });
      }
    }
  }
}
</script>

<style scoped>
.question-options {
  width: 785px;
}
.maximum {
  color: #2A93C0;
}
</style>
