<template>
  <div class="qa-div fs-14" v-if="item.options && item.options.length > 0">
    <h2 class="fs-16 text-primary-one fw-600">
      <span>Q{{ serial + 1 }}.</span>
      {{ item.title }}
    </h2>
    <div class="flex">
      <div class="i-ml-35 question-options">
        <h2 class="fs-14 mt-5 text-primary-one" v-for="(option, optIndex) in item.options" :key="optIndex" :class="{'inst-global-text-color': optIndex === maxIndex}">
          Option {{ optIndex + 1 }}: {{ option.title }}
        </h2>
      </div>

      <horizontal-chart v-if="listing === 'horizontal'" :data="item.options" :options-text="'number'" :maxIndex="maxIndex" />
      <donut-chart v-if="listing === 'pie'" :data="item.options" :options-text="'number'" :maxIndex="maxIndex" :render="true" />
      <bar-chart v-if="listing === 'bar'" :data="item.options" :options-text="'number'" :maxIndex="maxIndex"/>
    </div>
  </div>
</template>

<script>
import HorizontalChart from "../chart/HorizontalChart";
import DonutChart from "../chart/DonutChart";
import BarChart from "../chart/BarChart";
export default {
  name: "Dropdown",
  props: ['serial', 'item', 'listing', 'response', 'count'],
  components: {BarChart, DonutChart, HorizontalChart},
  created() {
    this.getAnswerCount();
  },
  data() {
    return {
      letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
      maxIndex: null
    }
  },
  methods: {
    getAnswerCount() {
      const self = this;
      let maxValue = 0;
      if (self.item.options) {
        self.item.options.forEach((opt, index) => {
          let data = self.response.filter(el => el === index);
          opt.percentage = (data.length / self.count) * 100;
          opt.flat_value = data.length;
          if(opt.percentage > maxValue) {
            maxValue = opt.percentage;
            self.maxIndex = index;
          }
        });
      }

    }
  }
}
</script>

<style scoped>
.question-options {
  width: 785px;
}
</style>
